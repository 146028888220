@import url(https://fonts.googleapis.com/css2?family=Baloo+Bhai+2&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Balsamiq+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}






.loginBg {
    background-color:rgba(2, 2, 2, 0.8);
    padding: 40px;
    border-radius: 10px;
    color: white;
}


.loginContainerBg {
    background-image: url(/static/media/LoginBackground.a43649df.jpg);
    object-fit: cover;
    object-position: center;
    background-size: 100%;
}

.vh100 {
    height: 100vh;
}

.signupBg {
    background-color:rgba(2, 2, 2, 0.8);
    padding: 40px;
    border-radius: 10px;
    color: white;
}


.signupContainerBg {
    background-image: url(/static/media/LoginBackground.a43649df.jpg);
    object-fit: cover;
    object-position: center;
    background-size: 100%;
}

.vh100 {
    height: 100vh;
}


.userText {
    font-family: "Baloo Bhai 2", cursive;
    font-size: 1.5rem;
  }
.Balsamiq {
  font-family: "Balsamiq Sans", cursive;
  font-size: 2.2rem;
}

.hover-black:hover {
    color: black;
}
a {
  cursor: hand !important;
  -webkit-text-decoration: None !important;
          text-decoration: None !important;
}

.contentHeight {
  min-height: 87vh;
}

